.btn-14 {
  position: relative;
  color: #00aced;
  border: none;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 3rem;
  height: 46px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 15px;
  font-size: 0.875rem;
  line-height: 1rem;
  white-space: nowrap;
  gap: 0.5rem;
  min-width: 48px;
cursor: pointer;
  font-weight: 400;
  font-size: 1.275rem;
  line-height: 1rem;
  white-space: nowrap;
  font-family: Bold;
}

.btn-14:before,
.btn-14:after {
  position: absolute;
  content: "";
  width: 20%;
  height: 20%;
  border: 1px solid;


  z-index: -1;
  transition: all 0.3s ease;
}

.btn-14:before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #00aced;
  border-left-color: #00aced;
}

.btn-14:after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #00aced;
  border-right-color: #00aced;
}

.btn-14:hover:before,
.btn-14:hover:after {
  border-color: #00aced;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 5px #00aced, 0 0 5px #00aced inset;
}