@font-face {
  font-family: Regular;
  src: url('../public/fonts/GilroyRegular.otf') format('opentype');
}
@font-face {
  font-family: Black;
  src: url('../public/fonts/GilroyBlack.otf') format('opentype');
}
@font-face {
  font-family: Bold;
  src: url('../public/fonts/GilroyBold.otf') format('opentype');
}
@font-face {
  font-family: Light;
  src: url('../public/fonts/GilroyLight.otf') format('opentype');
}
