/* VideoCarousel.css */

/* Estilo para as bolinhas indicadoras */
.slick-dots {

    bottom: 5px;
}

/* Estilo para a seta de navegação à direita */
.slick-next {
    right: 10px;
    z-index: 1;
}

/* Estilo para a seta de navegação à esquerda */
.slick-prev {
    left: 10px;
    z-index: 1;
}

/* Estilo para as bolinhas indicadoras personalizadas */
.custom-dot {
    margin-top: 50px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ddd;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

/* VideoCarousel.css */

/* Estilo para as bolinhas indicadoras */
.slick-dots {
    bottom: 10px;
}

/* Estilo para as bolinhas indicadoras personalizadas */
.custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ddd;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

/* Estilo para a bolinha ativa */
.custom-dot.active {
    background-color: #007bff;
}


.slick-prev,
.slick-next {
    font-size: 24px;
    /* Tamanho das setas - ajuste conforme necessário */
    color: #fff;
    /* Cor das setas */
    background-color: #333;
    /* Cor de fundo das setas */
    border-radius: 50%;
    /* Deixa as setas redondas */
    z-index: 1;
    /* Certifique-se de que as setas apareçam acima dos vídeos */
}

.slick-prev:hover,
.slick-next:hover {
    background-color: #555;
    /* Cor de fundo das setas ao passar o mouse */
}